import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import "./styles.css";

const ButtomComponent = (props) => {
  return (
    <Fragment>
      <div className="link-button-container">
        {props.eventOn ? (
          <Fragment>
            <div
              onClick={() => props.event()}
              className={"btn-link display-flex-center font-s-m " + props.color}
            >
              {props.texto}
              <span className="p-l-15 icon-font font-s-m">{props.icono}</span>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            {props.externalLink ? (
              <a
                href={props.link}
                target="_blank"
                rel="noopener noreferrer"
                className={
                  "btn-link display-flex-center font-s-m " + props.color
                }
              >
                {props.texto}
                <span className="p-l-15 icon-font font-s-m">{props.icono}</span>
              </a>
            ) : (
              <Link
                to={props.link}
                className={
                  "btn-link display-flex-center font-s-m " + props.color
                }
              >
                {props.texto}
                <span className="p-l-15 icon-font font-s-m">{props.icono}</span>
              </Link>
            )}
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

ButtomComponent.propTypes = {
  texto: PropTypes.string,
  link: PropTypes.string,
  color: PropTypes.string,
  icono: PropTypes.string,
  eventOn: PropTypes.bool,
  event: PropTypes.func,
  externalLink: PropTypes.bool,
};

export default ButtomComponent;
