import React, { Fragment, useRef, useEffect } from "react";
import { TimelineLite, Power3 } from "gsap";

import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import ButtomComponent from "../../../utilities/button";
import "./styles.css";

const ServicesComponent = () => {
  let services = useRef(null);
  let tl = new TimelineLite();

  useEffect(() => {
    const headlineFirst = services.children[1];
    const headlineSecond = headlineFirst.nextSibling.children[0];

    tl.staggerFrom(
      [headlineFirst.children, headlineSecond.children],
      1,
      {
        y: 400,
        ease: Power3.easeOut,
        delay: 0.8,
      },
      0.15,
      "start"
    );
  });
  return (
    <Fragment>
      <div className="services-container display-grid-center height-100vh">
        <Container fluid>
          <Row ref={(el) => (services = el)}>
            <Col md={12} lg={1}></Col>
            <Col
              md={12}
              lg={5}
              className="display-grid-center-l text-align-l col-s-1 overflow-contain"
            >
              <div className="font-s-xb">
                <div>Lo que te </div>
                <div>ofrecemos</div>
              </div>
              <div className="font-s-m m-t-30 m-b-30">
                <div>Hay más de una forma en la que podemos ayudarte.</div>
                <div>Elige la que más se adapte a tus necesidades.</div>
              </div>
              <div className="m-b-30">
                <ButtomComponent
                  link={"/contact"}
                  color={"text-color-pink"}
                  texto={"Solicitar cotización"}
                  icono={"8"}
                ></ButtomComponent>
              </div>
            </Col>
            <Col md={12} lg={5} className="text-align-l col-s-2">
              <div className="display-flex overflow-contain">
                <div className="development m-t-30 m-b-30">
                  <div className="font-s-b m-b-30">Desarrollo</div>
                  <div className="font-s-m m-b-30">
                    <span className="icon-font text-color-pink p-r-15">1</span>{" "}
                    Software instalado
                  </div>
                  <div className="font-s-m m-b-30">
                    <span className="icon-font text-color-pink p-r-15">2</span>{" "}
                    Apps / WebApps
                  </div>
                  <div className="font-s-m m-b-30">
                    <span className="icon-font text-color-pink p-r-15">3</span>{" "}
                    Realidad virtual
                  </div>
                  <div className="font-s-m m-b-30">
                    <span className="icon-font text-color-pink p-r-15">4</span>{" "}
                    Videojuegos
                  </div>
                </div>
                <div className="consultancy m-t-30">
                  <div className="font-s-b m-b-30">Consultoría</div>
                  <div className="font-s-m m-b-30">
                    <span className="icon-font text-color-pink p-r-15">5</span>{" "}
                    Arquitectura en la nube (AWS)
                  </div>
                  <div className="font-s-m m-b-30">
                    <span className="icon-font text-color-pink p-r-15">6</span>{" "}
                    Actualización de tecnologías
                  </div>
                  <div className="font-s-m m-b-30">
                    <span className="icon-font text-color-pink p-r-15">7</span>{" "}
                    Ui / Ux
                  </div>
                </div>
              </div>
            </Col>
            <Col md={12} lg={1}></Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};

export default ServicesComponent;
