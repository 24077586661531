import React, { Fragment, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { TweenMax, TimelineLite, Power3 } from "gsap";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import ButtomComponent from "../button";
import "./styles.css";

const FooterComponent = (props) => {
  let footer = useRef(null);
  let footerColOne = useRef(null);
  let footercoltwo = useRef(null);
  let tl = new TimelineLite();

  useEffect(() => {
    const frstCol = footerColOne.children[0];
    const secondCol = footercoltwo.children[0];

    TweenMax.to(footer, 0, { css: { visibility: "visible" } });

    tl.to(footer, 1.2, { css: { width: "100%" } }, "start");

    tl.staggerFrom(
      [frstCol.children],
      1,
      {
        x: 800,
        ease: Power3.easeOut,
        delay: 0.8,
      },
      0.15,
      "start"
    );

    tl.staggerFrom(
      [secondCol],
      1,
      {
        x: 800,
        ease: Power3.easeOut,
        delay: 0.8,
      },
      0.15,
      "start"
    );
  });

  return (
    <Fragment>
      <footer className="display-grid-center" ref={(el) => (footer = el)}>
        <Container fluid>
          <Row>
            <Col md={12} lg={1}></Col>
            <Col md={12} lg={5} className="text-align-l">
              <div>
                <div ref={(el) => (footerColOne = el)}>
                  <div className="p-b-15 font-s-b text-color-black text-footer-container">
                    <div>{props.text1} </div>
                    <div>{props.text2}</div>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={12} lg={5} ref={(el) => (footercoltwo = el)}>
              <div className="display-grid-center-r height-100 text-footer-container">
                <div>
                  <ButtomComponent
                    link={props.link}
                    color={"text-color-pink"}
                    texto={props.textButtom}
                    externalLink={props.externalLink}
                    icono={"8"}
                  ></ButtomComponent>
                </div>
              </div>
            </Col>
            <Col md={12} lg={1}></Col>
          </Row>
        </Container>
      </footer>
    </Fragment>
  );
};

FooterComponent.propTypes = {
  textButtom: PropTypes.string,
  text1: PropTypes.string,
  text2: PropTypes.string,
  link: PropTypes.string,
  externalLink: PropTypes.bool
};


export default FooterComponent;
