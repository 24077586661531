import { BrowserRouter, Route, Switch } from "react-router-dom";
import HomeContainer from "./components/home/container";
import ServicesContainer from "./components/servicesC/container";
import UsContainer from "./components/usComponent/container";
import ContactContainer from "./components/contact/container";
import ProjectsContainer from "./components/projectsC/container";
import './App.css';

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Switch>
          <Route exact path="/" component={HomeContainer} />
          <Route exact path="/services" component={ServicesContainer} />
          <Route exact path="/team" component={UsContainer} />
          <Route exact path="/contact" component={ContactContainer} />
          <Route exact path="/projects" component={ProjectsContainer} />
        </Switch>
      </div>
    </BrowserRouter>
  );
}

export default App;
