import React, { Fragment, useRef, useEffect } from "react";
import { TimelineLite, Power3 } from "gsap";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import "./styles.css";

const TeamComponent = () => {
  let team = useRef(null);
  let tl = new TimelineLite();

  useEffect(() => {
    const text = team.children[0];
    const imgTeam = text.nextSibling;

    const img = imgTeam.children[0].children[0].children;

    tl.staggerFrom(
      [text.children[0].children],
      1,
      {
        y: 400,
        ease: Power3.easeOut,
        delay: 0.8,
      },
      0.15,
      "start"
    );

    for (let i = 0; i < img.length; i++) {
      const a = img[i];
      tl.staggerFrom(
        [a.children],
        1,
        {
          y: 400,
          ease: Power3.easeOut,
          delay: 0.8,
        },
        0.15,
        "start"
      );
    }
  });

  return (
    <Fragment>
      <div className="team-container display-grid-center">
        <Container ref={(el) => (team = el)}>
          <Row>
            <Col md={12} className="overflow-contain">
              <div className="font-s-xb text-color-black">
                Pensando en grande
              </div>
              <div className="font-s-b text-color-black m-t-30">
                desde 2015.
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12} align="center">
              <div className="team-images-container m-t-60">
                <div className="image-container overflow-contain">
                  <img src="../../../../assets/img/team/chen.png" alt="" />
                </div>
                <div className="image-container overflow-contain">
                  <img src="../../../../assets/img/team/andy.png" alt="" />
                </div>
                <div className="image-container overflow-contain">
                  <img src="../../../../assets/img/team/chris.png" alt="" />
                </div>
                <div className="image-container overflow-contain">
                  <img src="../../../../assets/img/team/leo.png" alt="" />
                </div>
                <div className="image-container overflow-contain">
                  <img src="../../../../assets/img/team/abel.png" alt="" />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};

export default TeamComponent;
