import React, { Fragment, useRef, useEffect } from "react";
import { TweenMax, TimelineLite, Power3 } from "gsap";

import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import ButtomComponent from "../../../utilities/button";
import "./styles.css";

const HomeComponent = () => {
  let home = useRef(null);
  let contentColOne = useRef(null);
  let contentColTwo = useRef(null);
  let tl = new TimelineLite();

  useEffect(() => {
    const headlineFirst = contentColOne.children[0];
    const headlineSecond = headlineFirst.nextSibling;
    const button = contentColTwo.children[0];

    TweenMax.to(home, 0, { css: { visibility: "visible" } });

    tl.to(home, 1.2, { css: { backgroundPosition: "0% 0vh" } }, "start");

    tl.staggerFrom(
      [headlineFirst.children, headlineSecond.children],
      1,
      {
        y: 300,
        ease: Power3.easeOut,
        delay: 0.8,
      },
      0.15,
      "start"
    );

    tl.staggerFrom(
      [button.children],
      1,
      {
        y: 300,
        ease: Power3.easeOut,
        delay: 0.8,
      },
      0.15,
      "start"
    );
  });
  return (
    <Fragment>
      <div
        className="home-container height-100vh display-grid-center text-align-l"
        ref={(el) => (home = el)}
      >
        <div>
          <Container fluid>
            <Row ref={(el) => (home = el)}>
              <Col md={12} lg={1}></Col>
              <Col md={12} lg={7} ref={(el) => (contentColOne = el)}>
                <div className="font-s-xb text-color-white text-home-container">
                  <div>Soluciones</div>
                  <div>sofisticadas</div>
                </div>
                <div className="m-t-30 font-s-b text-color-white text-home-container p-b-15 p-t-15">
                  <div>para proyectos digitales.</div>
                </div>
              </Col>
              {/* <Col md={12} lg={6}></Col> */}
              <Col md={12} lg={3} ref={(el) => (contentColTwo = el)}>
                <div className="display-grid-center-r height-100 text-home-container">
                  <ButtomComponent
                    link={"/projects"}
                    color={"text-color-white"}
                    texto={"Ver Proyectos"}
                    icono={"8"}
                  ></ButtomComponent>
                </div>
              </Col>
              <Col md={12} lg={1}></Col>
            </Row>
          </Container>
        </div>
      </div>
    </Fragment>
  );
};

export default HomeComponent;
