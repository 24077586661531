import $ from "jquery";

export function mandarMail(data) {
  console.log(data);

  const message = `
    <body>
    <div align="center">
       Esta persona se puso en contacto con nosotros
    </div>
    <hr>
    <div>
      <b>Nombre:</b> <br> 
      ${data.nombre}
    </div>
    <div>
      <b>Email: </b> <br>
      ${data.correo}
    </div>
    <div>
    <b>Empresa: </b> <br>
       ${data.empresa}
    </div>
    <div>
      <b>Teléfono: </b> <br>
      ${data.telefono}
    </div>    
    <div>
      <b> Mensaje: </b> <br>
       ${data.mensaje}
    </div>
    </body>
    `;

  var form = new FormData();
  form.append("from", "hola@girasolo.com");
  form.append("emailPass", "C0nt4ct0!");
  form.append("to", "hola@girasolo.com");
  form.append("message", message);
  form.append("subject", data.asunto);

  var settings = {
    url: "https://sender-mail-big-pink.herokuapp.com/sendmail",
    method: "POST",
    timeout: 0,
    headers: {
      "content-type": "application/x-www-form-urlencoded",
      "cache-control": "no-cache",
    },
    processData: false,
    mimeType: "multipart/form-data",
    contentType: false,
    data: form,
  };

  $.ajax(settings).done(function (response) {
    if (response.ok === true) {
      document.getElementById("mensaje-enviado").style.display = "block";
    }
  });
}
