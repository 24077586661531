import React, { Component, Fragment } from 'react';
import TeamComponent from "../components/teamComponent";
import NavComponent from "../../utilities/navBar";
import FooterComponent from "../../utilities/footer";

class UsContainer extends Component {
    render() {
        return (
            <Fragment>
                <NavComponent colorT={"text-color-black-low"}></NavComponent>
                <TeamComponent></TeamComponent>
                <FooterComponent
                    text1={"Conoce Girasolo, nuestro "}
                    text2={"equipo de diseñadores"}
                    textButtom={"Conocer"}
                    externalLink={true}
                    link={"https://girasolo.mx/"}
                ></FooterComponent>
                <FooterComponent
                    text1={"Cosas que juntos"}
                    text2={"hemos desarrollado"}
                    textButtom={"Descubrir"}
                    link={"/projects"}
                ></FooterComponent>
            </Fragment>
        );
    }
}

export default UsContainer;