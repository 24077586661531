import React, { Fragment, useRef, useEffect } from "react";
import { TimelineLite, Power3 } from "gsap";

import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import FormComponent from "../contactForm";
import "./styles.css";

const ContactComponent = () => {
  let contactInfo = useRef(null);
  let tl = new TimelineLite();

  useEffect(() => {
    const rowOne = contactInfo.children[0];
    const rowTwo = contactInfo.children[1].children[1].children[0].children;

    tl.staggerFrom(
      [rowOne.children],
      1,
      {
        y: 400,
        ease: Power3.easeOut,
        delay: 0.8,
      },
      0.15,
      "start"
    );

    for (let i = 0; i < rowTwo.length; i++) {
      const a = rowTwo[i];
      tl.staggerFrom(
        [a.children],
        1,
        {
          y: 400,
          ease: Power3.easeOut,
          delay: 0.8,
        },
        0.15,
        "start"
      );
    }
  });
  return (
    <Fragment>
      <div className="contact-container text-align-l display-grid-center">
        <Container fluid ref={(el) => (contactInfo = el)}>
          <Row className="overflow-contain">
            <Col md={12} lg={1}></Col>
            <Col md={12} lg={11}>
              <div className="font-s-xb text-align-l text-color-black m-b-60">
                Hagamos algo grande
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12} lg={1}></Col>
            <Col md={12} lg={4}>
              <div className="display-grid-center-l">
                <div className="font-s-b m-b-30 p-b-15 overflow-contain">
                  <div>
                    <a
                      className="text-color-black"
                      href="whatsapp://send?abid=3521254523&text=Hola"
                    >
                      352 125 4523
                    </a>
                  </div>
                </div>
                <div className="font-s-m m-b-30 p-b-15 overflow-contain">
                  <div>
                    <a
                      className="text-color-black"
                      href="mailto:hola@girasolo.com"
                    >
                      {" "}
                      hola@girasolo.com
                    </a>
                  </div>
                </div>
                <div className="font-s-b m-b-30 p-b-15 overflow-contain">
                  <div> Visítanos</div>
                </div>
                <div className="font-s-m overflow-contain p-b-15">
                  <div>
                    <a
                      className="text-color-black"
                      href="https://g.page/girasolo?share"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Paseo de los Álamos 2{" "}
                    </a>
                  </div>
                  <div>
                    <a
                      className="text-color-black"
                      href="https://g.page/girasolo?share"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Santa Ana Pacueco, Gto. MX.
                    </a>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={12} lg={6}>
              <FormComponent></FormComponent>
            </Col>
            <Col md={12} lg={1}></Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};

export default ContactComponent;
