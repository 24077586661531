import React, { Component, Fragment } from "react";
import CarouselComponent from "../components/carouselComponent";
import NavComponent from "../../utilities/navBar";
import FooterComponent from "../../utilities/footer";
import Projects from "../../utilities/projects.json";

class ProjectsContainer extends Component {
  constructor() {
    super();
    this.state = {
      dataProject: {},
    };
    this.findProject = this.findProject.bind(this);
    this.proyecto = this.proyecto.bind(this);
  }

  componentDidMount() {
    this.findProject();
  }

  findProject(id) {
    if (id === undefined) {
      for (let i of Projects) {
        if (i.id === "1") {
          this.setState({ dataProject: i });
        }
      }
    } else {
      for (let i of Projects) {
        if (i.id === id) {
          this.setState({ dataProject: i });
        }
      }
    }
  }

  proyecto(pro) {
    const resultado = Projects.find(
      (proyecto) => proyecto.id === this.state.dataProject.siguientePId
    );
    this.setState({ dataProject: resultado });
  }

  render() {
    const { dataProject } = this.state;

    return (
      <Fragment>
        <NavComponent colorT={"text-color-black-low"}></NavComponent>
        {dataProject.tipo !== undefined ? (
          <Fragment>
            <CarouselComponent
              nextP={this.findProject}
              project={dataProject}
              findFunc={this.proyecto}
            ></CarouselComponent>
            <FooterComponent
              text1={"¿Qué podemos "}
              text2={"hacer por ti?"}
              textButtom={"Descubrir"}
              link={"/services"}
            ></FooterComponent>
          </Fragment>
        ) : (
          <Fragment></Fragment>
        )}
      </Fragment>
    );
  }
}

export default ProjectsContainer;
