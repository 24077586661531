import React, { Component, Fragment } from "react";
import HomeComponent from "../components/homeComponent";
import NavComponent from "../../utilities/navBar";
import FooterComponent from "../../utilities/footer";
class HomeContainer extends Component {
  render() {
    return (
      <Fragment>
        <NavComponent colorT={"text-color-white"}></NavComponent>
        <HomeComponent></HomeComponent>
        <FooterComponent
          text1={"¿Qué podemos "}
          text2={"hacer por ti?"}
          textButtom={"Descubrir"}
          link={"/services"}
        ></FooterComponent>
      </Fragment>
    );
  }
}

export default HomeContainer;
