import React, { Component, Fragment } from "react";
import NavComponent from "../../utilities/navBar";
import ContactComponent from "../components/contactInfo";

class ContactContainer extends Component {
  render() {
    window.scrollTo(0, 0);
    return (
      <Fragment>
        <NavComponent colorT={"text-color-black-low"}></NavComponent>
        <ContactComponent></ContactComponent>
      </Fragment>
    );
  }
}

export default ContactContainer;
