import React, { Fragment, useEffect, useRef, forwardRef } from "react";
import PropTypes from "prop-types";
import { TimelineLite, Power3, gsap } from "gsap";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import ButtomComponent from "../../../utilities/button";
import Projects from "../../../utilities/projects.json";
import "./styles.css";

const CarouselComponent = (props) => {
  let cover = useRef(null);
  let info = useRef(null);
  let controls = useRef(null);
  let overlay = useRef(null);
  let homeInfo = useRef(null);
  let container = useRef(null);
  let tl = new TimelineLite();

  useEffect(() => {
    const coverImgStart = cover.children[0].children;
    const indicadorIdAnterior = document.getElementById(
      "project-" + props.project.anteriorPId
    );
    const indicadorId = document.getElementById("project-" + props.project.id);

    indicadorId.classList = "indicator-container indicator-container-active";
    indicadorIdAnterior.classList = "indicator-container";

    const type = info.children[0];
    const name = type.nextSibling;
    const seeMore = name.nextSibling;
    const position = seeMore.nextSibling;
    const button = controls;

    tl.staggerFrom(
      [coverImgStart],
      1,
      {
        opacity: 0,
        y: 400,
        ease: Power3.ease,
        delay: 0,
      },
      0.1,
      "start"
    );

    tl.staggerFrom(
      [type.children, name.children, seeMore.children, button.children],
      1,
      {
        opacity: 0,
        y: 400,
        ease: Power3.ease,
        delay: 0,
      },
      0.1,
      "start"
    );

    tl.staggerFrom(
      [position.children],
      1,
      {
        opacity: 0,
        ease: Power3.ease,
        delay: 0,
      },
      0.1,
      "start"
    );
  });

  const next = () => {
    props.nextP(props.project.siguientePId);
  };

  const prev = () => {
    props.nextP(props.project.anteriorPId);
  };

  const openIfo = () => {
    const coverImgHidden = cover.children[0].children;

    const span = document
      .getElementById("link-container")
      .getElementsByTagName("span");

    const overlayC = overlay;

    const typeHidden = info.children[0];
    const nameHidden = typeHidden.nextSibling;
    const seeMoreHidden = nameHidden.nextSibling;
    const positionHidden = seeMoreHidden.nextSibling;
    const button = controls;

    const nameInfo = homeInfo.children[0];
    const cols = container.getElementsByClassName("row");

    tl.staggerTo(
      [coverImgHidden],
      1,
      {
        y: -400,
        opacity: "0",
        ease: Power3.ease,
        delay: 0,
      },
      0.5,
      "hidden"
    );

    tl.staggerTo(
      [
        typeHidden.children,
        nameHidden.children,
        seeMoreHidden.children,
        positionHidden.children,
        button.children,
      ],
      1,
      {
        y: -400,
        opacity: "0",
        ease: Power3.ease,
        delay: 0,
      },
      0,
      "hidden"
    );

    tl.staggerTo(
      [overlayC],
      1,
      {
        width: "100%",
        height: "100vh",
        opacity: "1",
        ease: Power3.ease,
        delay: 0,
      },
      0,
      "hidden"
    );

    tl.staggerTo(
      [overlayC],
      1,
      {
        height: "auto",
        borderRadius: "0%",
        backgroundColor: "#F3F3F4",
        ease: Power3.ease,
        delay: 0.5,
      },
      2
    ).staggerTo(
      [overlayC],
      2,
      { overflow: "unset", ease: Power3.ease, delay: 1 },
      1
    );

    for (let i = 0; i < span.length; i++) {
      const a = span[i];
      if (a.classList.contains("text-color-black-low") === true) {
        a.classList = "text-color-white";
      }
    }

    tl.from(
      [nameInfo.children],
      2,
      {
        x: 400,
        opacity: "1",
        ease: Power3.ease,
        delay: 2,
      },
      2,
      "show"
    );

    for (let i = 1; i < homeInfo.children.length; i++) {
      tl.from(
        [homeInfo.children[i]],
        2,
        {
          opacity: 1,
          x: 1300,
          ease: Power3.ease,
          delay: 2,
        },
        2,
        "show"
      );
    }

    let rowChilds = [];
    for (let i = 0; i < cols.length; i++) {
      const a = cols[i];
      for (let j = 0; j < a.children.length; j++) {
        const b = a.children[j];
        for (let k = 0; k < b.children.length; k++) {
          const c = b.children[k];
          rowChilds.push(c);
        }
      }
    }
    for (let i = 0; i < rowChilds.length; i++) {
      const a = rowChilds[i];
      gsap.from(
        [a],
        2,
        {
          y: "400",
          ease: Power3.ease,
          delay: 2,
        },
        2
      );
    }
  };

  const closeInfo = () => {
    window.scrollTo(0, 0);
    props.findFunc(Projects);
  };

  const changeProject = (id) => {
    props.nextP(id.toString());
  };

  const ProjectComponent = forwardRef((props, ref) => {
    return (
      <Fragment>
        <div
          id="someID"
          className=""
          style={{ backgroundColor: props.project.color }}
        >
          <div
            ref={(el) => (homeInfo = el)}
            className="info-project-header height-90vh display-grid-center"
          >
            <div className="overflow-contain project-name-content">
              <div
                id="overlay-project-name"
                className="index-position font-s-xb text-color-white"
              >
                {props.project.nombre.map((index, i) => {
                  return (
                    <Fragment key={i}>
                      <div>{props.project.nombre[i].text}</div>
                    </Fragment>
                  );
                })}
              </div>
              <div
                id="project-type"
                className="m-t-30 index-position font-s-m-medium text-color-white"
              >
                {props.project.tipo}
              </div>
              {props.project.id === "1" ? (
                <Fragment>
                  <div className="m-t-30 project-app-links">
                    <div className="m-r-30 index-position font-s-m-medium text-color-white">
                      <div>Disponible para</div>
                      <div>Android y iOS</div>
                    </div>
                    <div>
                      <img
                        className="img-links"
                        src={
                          "../../../../assets/img/projectsImg" +
                          props.project.links[0].img
                        }
                        alt=""
                      />
                      <img
                        className="img-links"
                        src={
                          "../../../../assets/img/projectsImg" +
                          props.project.links[1].img
                        }
                        alt=""
                      />
                    </div>
                  </div>
                </Fragment>
              ) : (
                <Fragment></Fragment>
              )}
            </div>
            {props.project.imagen !== undefined ? (
              props.project.imagen.map((index, i) => {
                return (
                  <Fragment key={i}>
                    {i === 0 ? (
                      <Fragment>
                        <img
                          className="info-project-img info-project-img-index"
                          src={
                            "../../../../assets/img/projectsImg" +
                            props.project.imagen[i].img
                          }
                          alt=""
                        />
                      </Fragment>
                    ) : (
                      <Fragment>
                        <img
                          className="info-project-img object-img"
                          src={
                            "../../../../assets/img/projectsImg" +
                            props.project.imagen[i].img
                          }
                          alt=""
                        />
                      </Fragment>
                    )}
                  </Fragment>
                );
              })
            ) : (
              <Fragment></Fragment>
            )}
          </div>
        </div>
        <Container fluid ref={(el) => (container = el)}>
          <Row className="m-t-30 m-b-30 height-40vh">
            <Col md={12} lg={1}></Col>
            <Col
              md={12}
              lg={5}
              align="left"
              className="display-grid-center overflow-contain"
            >
              <div className="font-s-xb">Encomienda</div>
            </Col>
            <Col
              md={12}
              lg={4}
              className="display-grid-center overflow-contain"
            >
              <div className="font-s-m-medium text-align-l">
                {props.project.encomienda}
              </div>
            </Col>
            <Col md={12} lg={2}></Col>
          </Row>
          <Row className="m-b-60 p-b-15" align="left">
            <Col md={12} lg={1}></Col>
            <Col
              md={12}
              lg={11}
              className="display-grid-center overflow-contain"
            >
              <div className="font-s-xb p-b-15"> ¿Qué logramos? </div>
            </Col>
          </Row>
          <Row align="left">
            <Col md={12} lg={1}></Col>
            {props.project.logros !== undefined ? (
              props.project.logros.map((index, i) => {
                return (
                  <Fragment key={i}>
                    <Col
                      md={12}
                      lg={3}
                      className="m-t-60 m-b-60 overflow-contain"
                    >
                      <div className="display-flex-center">
                        <div className="font-s-b text-color-pink">
                          {props.project.logros[i].numeros}
                        </div>
                        <div className="font-s-m-medium text-color-black p-l-15">
                          {props.project.logros[i].logro}
                        </div>
                      </div>
                    </Col>
                  </Fragment>
                );
              })
            ) : (
              <Fragment></Fragment>
            )}
            <Col md={12} lg={1}></Col>
          </Row>
          <Row className="m-t-60 m-b-60" align="left">
            <Col md={12} lg={1}></Col>
            <Col
              md={12}
              lg={11}
              className="display-grid-center overflow-contain"
            >
              <div className="font-s-xb"> Intervención </div>
            </Col>
          </Row>

          {props.project.intervencion !== undefined ? (
            props.project.intervencion.map((index, i) => {
              return (
                <Fragment key={i}>
                  <Row className="m-t-60 m-b-60" align="left">
                    <Col md={12} lg={1}></Col>
                    <Col
                      md={12}
                      lg={1}
                      className="display-flex-center overflow-contain"
                    >
                      <div className="font-s-m text-color-pink"> 0{i + 1}</div>
                    </Col>
                    <Col
                      md={12}
                      lg={4}
                      className="display-flex-center inversion-m overflow-contain"
                    >
                      <div className="font-s-b text-color-pink">
                        {props.project.intervencion[i].texto1}
                      </div>
                    </Col>
                    <Col md={12} lg={1}></Col>
                    <Col
                      md={12}
                      lg={3}
                      className="display-flex-center overflow-contain"
                    >
                      <div className="font-s-m-medium text-color-black">
                        {props.project.intervencion[i].texto2}
                      </div>
                    </Col>
                    <Col md={12} lg={1}></Col>
                  </Row>
                  <Row>
                    <Col md={12} className="img-container">
                      <img
                        className={
                          props.project.intervencion[i].id === "2" ? (
                            "img-w-100"
                          ) : (
                            <Fragment></Fragment>
                          )
                        }
                        src={
                          "../../../../assets/img/projectsImg" +
                          props.project.intervencion[i].img
                        }
                        alt=""
                      />
                    </Col>
                  </Row>
                </Fragment>
              );
            })
          ) : (
            <Fragment></Fragment>
          )}
          <Row className="m-t-60 m-b-60" align="left">
            <Col md={12} lg={1}></Col>
            <Col
              md={12}
              lg={11}
              className="display-grid-center overflow-contain"
            >
              <div className="font-s-xb"> Opinión </div>
            </Col>
          </Row>
          <Row className="m-t-60 m-b-60" align="left">
            <Col md={12} lg={1}></Col>
            <Col
              md={12}
              lg={5}
              className="display-grid-center overflow-contain"
            >
              <div className="font-s-b m-b-30">
                {props.project.opiniones.nombre}
              </div>
              <div className="font-s-m inversion-m text-color-pink">
                {props.project.opiniones.puesto}
              </div>
            </Col>
            <Col
              md={12}
              lg={4}
              className="display-grid-center overflow-contain"
            >
              <div className="display-flex-center">
                <div className="font-s-b text-color-pink">"</div>
                <div className="font-s-m-medium p-l-15">
                  {props.project.opiniones.opinion}
                </div>
              </div>
            </Col>
            <Col md={12} lg={2}></Col>
          </Row>
          <Row>
            <Col md={12} className="overflow-contain">
              <div className="project-contact-content display-grid-center height-40vh">
                <div>
                  <div className="font-s-b m-b-30">
                    <div>¿Tienes un proyecto?</div>
                    <div> Hagámoslo realidad</div>
                  </div>
                  <ButtomComponent
                    link={"/contact"}
                    color={"text-color-pink display-grid-center-c"}
                    texto={"Contactar"}
                    icono={"8"}
                  ></ButtomComponent>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="height-40vh">
            <Col md={12} lg={1}></Col>
            <Col
              md={12}
              lg={5}
              className="display-grid-center-l overflow-contain"
            >
              <div
                id="overlay-project-name-footer"
                className="font-s-b text-color-black"
              >
                {props.project.nombre.map((index, i) => {
                  return (
                    <Fragment key={i}>
                      <div>{props.project.nombre[i].text}</div>
                    </Fragment>
                  );
                })}
              </div>
            </Col>
            <Col
              md={12}
              lg={5}
              className="display-grid-center-r overflow-contain"
            >
              <ButtomComponent
                link={"#"}
                color={"text-color-pink"}
                texto={"Siguiente proyecto"}
                icono={"8"}
                eventOn={true}
                event={closeInfo}
              ></ButtomComponent>
            </Col>
            <Col md={12} lg={1}></Col>
          </Row>
        </Container>
      </Fragment>
    );
  });

  return (
    <Fragment>
      <div
        id="overlay"
        ref={(el) => (overlay = el)}
        className="overlay-expansion display-grid-center"
      >
        <ProjectComponent
          project={props.project}
          ref={ProjectComponent}
        ></ProjectComponent>
      </div>
      <div className="project-container display-grid-center height-100vh">
        <Container fluid>
          <Row>
            <Col md={12} lg={6} ref={(el) => (cover = el)} align="center">
              <div className="overflow-contain">
                <img
                  onClick={() => openIfo()}
                  id="cover"
                  className="project-cover"
                  src={
                    "../../../../assets/img/projectsImg" + props.project.portada
                  }
                  alt=""
                />
              </div>
            </Col>
            <Col md={12} lg={4}>
              <div className="display-grid-center-l height-100">
                <div ref={(el) => (info = el)}>
                  <div className="overflow-contain font-s-m text-color-black m-b-30 type-project">
                    <div>{props.project.tipo}</div>
                  </div>
                  <div
                    id="project-name"
                    className="overflow-contain font-s-xb m-b-30"
                  >
                    {props.project.nombre.map((index, i) => {
                      return (
                        <Fragment key={i}>
                          <div>{props.project.nombre[i].text}</div>
                        </Fragment>
                      );
                    })}
                  </div>
                  <div className="m-b-30 overflow-contain">
                    <ButtomComponent
                      link={"#"}
                      color={"text-color-pink"}
                      texto={"Ver proyecto"}
                      icono={"8"}
                      eventOn={true}
                      event={openIfo}
                    ></ButtomComponent>
                  </div>
                  <div className="display-flex-center overflow-contain">
                    {Projects.map((index, i) => {
                      return (
                        <Fragment key={i}>
                          <div
                            id={"project-" + (i + 1)}
                            onClick={() => changeProject(i + 1)}
                            className="indicator-container"
                          ></div>
                        </Fragment>
                      );
                    })}
                  </div>
                </div>
              </div>
            </Col>
            <Col md={12} lg={2} className="overflow-contain controls p-b-15">
              <div
                ref={(el) => (controls = el)}
                className="display-grid-center-l height-100"
              >
                <div className="btn-next icon-font" onClick={() => next()}>
                  8
                </div>
                <div className="btn-prev icon-font" onClick={() => prev()}>
                  8
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};

CarouselComponent.propTypes = {
  project: PropTypes.object,
  findFunc: PropTypes.func,
  nextP: PropTypes.func,
  infoActive: PropTypes.func,
};

export default CarouselComponent;
