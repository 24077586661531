import React, { Fragment, useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { TweenMax, TimelineLite, Power3 } from "gsap";

import { Navbar, Nav } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import $ from "jquery";
import "./styles.css";

const NavComponent = (props) => {
  let nav = useRef(null);
  let contentImg = useRef(null);
  let contentLinks = useRef(null);
  const [tl] = useState(new TimelineLite());

  useEffect(() => {
    const x = window.matchMedia("(max-width: 999px)");
    const linkFirst = contentLinks.children[0];
    const linkSecond = linkFirst.nextSibling;
    const linkThird = linkSecond.nextSibling;
    const linkFourt = linkThird.nextSibling;

    TweenMax.to(nav, 0, { css: { visibility: "visible" } });

    if (x.matches === true) {
      const span = document.getElementById("link-container").getElementsByTagName("span");
      tl.to(nav, 0, { css: { height: "auto" } });
      for (let i = 0; i < span.length; i++) {
        const a = span[i];
        if (a.classList.contains("text-color-black-low") === true) {
          a.classList = "text-color-white";
        }
      }
    } else {
      tl.to(nav, 0, { css: { height: "14vh" } });
    }

    tl.staggerFrom(
      [contentImg.children],
      1,
      {
        y: -150,
        ease: Power3.easeOut,
        delay: 0.8,
      },
      0.15,
      "start"
    );

    tl.staggerFrom(
      [linkFirst, linkSecond, linkThird, linkFourt],
      1,
      {
        x: 600,
        ease: Power3.easeOut,
        delay: 0.8,
      },
      0.15,
      "start"
    );

    if (x.matches === true) {
      tl.to(
        nav,
        1,
        {
          css: { backgroundColor: "#d84e6a" },
          ease: Power3.easeOut,
          delay: 0,
        },
        0,
        "start"
      );
    } else { }
  }, [tl]);

  const navColor = () => {
    $(function () {
      $(window).scroll(function () {

        const nav = document.getElementById("nav");
        const span = document.getElementById("link-container").getElementsByTagName("span");
        const x = window.matchMedia("(max-width: 999px)");

        if (x.matches === false) {
          const overlay = document.getElementById("overlay");

          if ($(this).scrollTop() > 100) {
            nav.classList =
              "navbar navbar-expand-lg navbar-light bg-gray fixed-top";
            for (let i = 0; i < span.length; i++) {
              const a = span[i];
              if (a.classList.contains("text-color-black-low") === true) {
                a.classList = "text-color-white";
              }
            }
          } else {
            nav.classList =
              "navbar navbar-expand-lg navbar-light fixed-top";
            for (let i = 0; i < span.length; i++) {
              const a = span[i];
              if (a.classList.contains("text-color-black-low") === false) {
                if (overlay) {
                  if (overlay.style.height === "auto") {
                    a.classList = "text-color-white";
                  } else {
                    a.classList = props.colorT;
                  }
                } else {
                  a.classList = props.colorT;
                }
              }
            }
          }
        }
      });
    });
  };

  navColor();
  return (
    <Fragment>
      <Navbar fixed="top" id="nav" expand="lg" ref={(el) => (nav = el)}>
        <Navbar.Brand href="/" ref={(el) => (contentImg = el)}>
          <img
            className="img-nav"
            src="../../../assets/img/nav/LogoBP.jpg"
            alt=""
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse className="justify-content-end">
          <Nav id="link-container" ref={(el) => (contentLinks = el)}>
            <div className="link-nav-container">
              <Nav.Link href="/services" className="font-s-m">
                <span id="span" className={props.colorT}>
                  Servicios
                </span>
              </Nav.Link>
            </div>
            <div className="link-nav-container">
              <Nav.Link href="/projects" className="font-s-m ">
                <span className={props.colorT}>Proyectos</span>
              </Nav.Link>
            </div>
            <div className="link-nav-container">
              <Nav.Link href="/team" className="font-s-m ">
                <span className={props.colorT}>Nosotros</span>
              </Nav.Link>
            </div>
            <div className="link-nav-container">
              <Nav.Link href="/contact" className="font-s-m ">
                <span className={props.colorT}>Contacto</span>
              </Nav.Link>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </Fragment>
  );
};

NavComponent.propTypes = {
  colorT: PropTypes.string,
  active: PropTypes.bool
};

export default NavComponent;
