import React, { Component, Fragment } from "react";
import ServicesComponent from "../components/servicesComponent";
import NavComponent from "../../utilities/navBar";
import FooterComponent from "../../utilities/footer";

class ServicesContainer extends Component {
  render() {
    window.scrollTo(0, 0);
    return (
      <Fragment>
        <NavComponent colorT={"text-color-black-low"}></NavComponent>
        <ServicesComponent></ServicesComponent>
        <FooterComponent
          text1={"Conoce Girasolo, nuestro "}
          text2={"equipo de diseñadores"}
          textButtom={"Conocer"}
          externalLink={true}
          link={"https://girasolo.mx/"}
        ></FooterComponent>
        <FooterComponent
          text1={"¿Tienes un proyecto? "}
          text2={"Hagámoslo realidad"}
          textButtom={"Contactar"}
          link={"/contact"}
        ></FooterComponent>
      </Fragment>
    );
  }
}

export default ServicesContainer;
