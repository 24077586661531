import React, { Fragment, useRef, useEffect, useState } from "react";
import { TimelineLite, Power3 } from "gsap";
import { mandarMail } from "./services";
import { Form, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles.css";

const FormComponent = () => {
  let formContact = useRef(null);
  const [tl] = useState(new TimelineLite());

  const [validated, setValidated] = useState(false);
  const [contacto, guardarContacto] = useState({
    nombre: "",
    empresa: "",
    correo: "",
    telefono: "",
    asunto: "",
    mensaje: "",
  });

  useEffect(() => {
    const inputRowFirst = formContact.children[0];
    const inputRowSecond = inputRowFirst.nextSibling;
    const inputRowThird = inputRowSecond.nextSibling;
    const inputRowFourt = inputRowThird.nextSibling;
    const link = inputRowFourt.nextSibling;

    tl.staggerFrom(
      [
        inputRowFirst.children,
        inputRowSecond.children,
        inputRowThird.children,
        inputRowFourt.children,
        link.children,
      ],
      1,
      {
        y: 400,
        ease: Power3.easeOut,
        delay: 0.8,
      },
      0.15,
      "start"
    );
  }, [tl]);

  const actualizarState = (e) => {
    guardarContacto({
      ...contacto,
      [e.target.name]: e.target.value,
    });
  };

  const submitMensaje = (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }

    if (form.checkValidity() === true) {
      mandarMail(contacto);
    }

    setValidated(true);
  };

  const { nombre, empresa, correo, telefono, asunto, mensaje } = contacto;

  return (
    <Fragment>
      <div className="contact-form-container">
        <Form
          noValidate
          validated={validated}
          onSubmit={submitMensaje}
          ref={(el) => (formContact = el)}
        >
          <Row className="overflow-contain">
            <Col md={12} lg={6}>
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label className="font-s-m">Nombre</Form.Label>
                <Form.Control
                  type="text"
                  value={nombre}
                  name="nombre"
                  onChange={actualizarState}
                  required
                  placeholder="Nombre"
                />
                <Form.Control.Feedback type="invalid">
                  Campo requerido
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={12} lg={6}>
              <Form.Group controlId="exampleForm.ControlInput2">
                <Form.Label className="font-s-m">Empresa</Form.Label>
                <Form.Control
                  type="text"
                  value={empresa}
                  name="empresa"
                  onChange={actualizarState}
                  required
                  placeholder="Nombre de tu empresa"
                />
                <Form.Control.Feedback type="invalid">
                  Campo requerido
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row className="overflow-contain">
            <Col md={12} lg={6}>
              <Form.Group controlId="exampleForm.ControlInput3">
                <Form.Label className="font-s-m">Teléfono</Form.Label>
                <Form.Control
                  type="text"
                  value={telefono}
                  name="telefono"
                  onChange={actualizarState}
                  required
                  placeholder="Número de teléfono"
                />
                <Form.Control.Feedback type="invalid">
                  Campo requerido
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={12} lg={6}>
              <Form.Group controlId="exampleForm.ControlInput4">
                <Form.Label className="font-s-m">E-mail</Form.Label>
                <Form.Control
                  type="email"
                  value={correo}
                  name="correo"
                  onChange={actualizarState}
                  required
                  placeholder="Correo electrónico"
                />
                <Form.Control.Feedback type="invalid">
                  Campo requerido
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row className="overflow-contain">
            <Col md={12}>
              <Form.Group controlId="exampleForm.ControlInput5">
                <Form.Label className="font-s-m">Asunto</Form.Label>
                <Form.Control
                  type="text"
                  value={asunto}
                  name="asunto"
                  onChange={actualizarState}
                  required
                  placeholder="Asunto"
                />
                <Form.Control.Feedback type="invalid">
                  Campo requerido
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row className="overflow-contain">
            <Col md={12}>
              <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Label className="font-s-m">Mensaje</Form.Label>
                <Form.Control
                  type="text"
                  as="textarea"
                  rows={2}
                  value={mensaje}
                  name="mensaje"
                  onChange={actualizarState}
                  required
                  placeholder="Escribe aquí tu mensaje..."
                />
                <Form.Control.Feedback type="invalid">
                  Campo requerido
                </Form.Control.Feedback>
              </Form.Group>
              <div id="mensaje-enviado" className="mensaje-enviado m-b-30 font-s-m-medium text-color-pink">
                Gracias por ponerte en contacto con nosotros. El equipo de
                 <b>BigPink</b> se pondrá en contacto contigo para resolver tus
                dudas.
              </div>
            </Col>
          </Row>
          <div className="overflow-contain">
            <button
              type="submit"
              className="display-flex-center font-s-m text-color-pink overflow-contain"
            >
              Enviar mensaje{" "}
              <span className="p-l-15 icon-font font-s-m">8</span>
            </button>
          </div>
        </Form>
      </div>
    </Fragment>
  );
};

export default FormComponent;
